import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { graphql } from "gatsby"
import ConditionalWrapper from "../components/conditional-wrapper"

const OurWork = ({ data }) => {
    return (
        <Layout className='page-clients'>

          <SEO title='Our Work'/>
          <article>
            <h1>Our Work</h1>

            <Row>
              <Col xs={12} sm={6}>
                <p className='lead'>BHK's team, together and individually, have worked with many organisations spanning the full spectrum of the aviation and aerospace industry, including manufacturers, airlines, airports, business jet and military operators as well as para-public services, law enforcement agencies, research bodies and businesses in mining, automotive, tourism and heavy industry, amongst others.</p>

                <p>We have also worked with governments and global emergency management organisations in responding to natural and man-made disasters, including the 2004 Asia Tsunami.</p>

                <p>Our assignments have been varied. Some required a global and full suite of Crisis Communications activities, others involved individual components. Some have required a clean-sheet approach to developing a comprehensive crisis communications capacity.  This started with a reputation risk review and analysis of any existing processes and procedures, and then devising the necessary materials, manuals and policy doctrines and training for when they need to be put into action.</p>

              </Col>

              <Col xs={12} sm={6}>
                <h3>Training</h3>
                <p>We have arranged training programmes for executives and managers who had then to face families of victims and survivors of crisis events, the media, public officials, oversight and public accountability bodies and many other stakeholders.</p>

                <h3>Exercise and Drill Programmes</h3>
                <p>As an extension of the training, we delivered focussed complex exercises or real-time simulations with realistic scenarios to test the individual components of the organisation’s crisis communications response as well as its effective and coordinated response.</p>

                <h3>On-Call 24/7</h3>
                <p>We have also provided on-call 24/7/365 strategic counsel, tactical guidance and any other support that was required in those dire circumstances. This included media relations, media monitoring analysis, preparing briefing notes and rehearsing an organisation’s leaders or representatives before they appear before various audiences in a very stressful, emotionally loaded and totally unfamiliar environment.</p>

                <p>All told, we have an unmatched track record of hands-on experience in all aspects of Crisis Communications</p>
              </Col>

            </Row>

            <Row xs={1} md={2} lg={3} className='mt-4 clientsList justify-content-center align-items-center'>
              {data.clients.nodes.map(client => {
                let scaleFactor = client.logoScaleFactor || 1;
                return (
                  <Col className='my-5 text-center' key={client.id}>
                    <ConditionalWrapper
                      condition={client.url}
                      wrapper={children =>
                        <a href={client.url} target='_blank' rel='noreferrer'>{children}</a>
                      }>
                      <img src={client.logo.file.url} alt={client.name} className='img-fluid' style={{ height: `${3 * scaleFactor}rem` }}/>
                      {/*<InlineSVG svg={client.logo.svg} alt={client.name} className='img-fluid' style={{ height: `${3 * scaleFactor}rem` }} />*/}
                    </ConditionalWrapper>

                  </Col>
                )
              })}
            </Row>
          </article>

        </Layout>
    )
}

export default OurWork;

export const query = graphql`
query {
  clients: allContentfulClient(sort: {fields: name}, filter: {logoColour: {file: {contentType: {eq: "image/svg+xml"}}}}) {
    nodes {
      id
      name
      description {
        raw
      }
      logoScaleFactor
      logo: logoColour {
        svg {
          absolutePath
          content
          dataURI
          relativePath
        }
        file {
          details {
            image {
              height
              width
            }
          }
          url
        }
      }
    }
  }
}`
